import React, { useEffect } from "react"
import { graphql } from "gatsby"
import SocialNetworkRow from "../components/social_networks_row"
import ArticleCard from "../components/article_card"
import Label, { BorderColor, FillLabelColor } from "../components/label_pill"
import {
  ArticleCardType,
  articlesCardsListFromMap,
} from "../types/article_card_type"
import LevelLabel from "../components/level_label"
import { SEO } from "../components/seo"
import BreadcrumbComponent from "../components/breadcrumb"
import { getCategoryKey, getCategoryName } from "../utils/functions"
import { useTranslation } from "react-i18next"
import RoundedButton from "../components/rounded_button"
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image"
import Layout from "../components/blog/blog-layout"
import { HTMLReactParserOptions, Element } from "html-react-parser"

const ArticlePage = ({ pageContext, data }) => {
  const html = pageContext.html
  const parse = require("html-react-parser")
  const { t } = useTranslation()

  let articlesList: ArticleCardType[] = []

  articlesList = articlesCardsListFromMap(data.suggestedArticles.edges)

  const replaceImage: HTMLReactParserOptions = {
    replace: node => {
      if (
        node instanceof Element &&
        node.type === "tag" &&
        node.name === "img" &&
        node.attribs.src != ""
      ) {
        const image = data.articleImages.body.medias.filter(
          image => image.src == node.attribs.src
        )
        const src = getImage(image[0].localFile.childrenImageSharp[0])!
        return <GatsbyImage image={src} alt={node.attribs.alt} />
      }
    },
  }

  useEffect(() => {
    if (typeof window !== undefined && typeof document !== undefined) {
      // Cria o elemento de script
      const script = document.createElement('script');

      // Define o conteúdo do script
      script.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-P23GV22X63');
      `;

      // Insere o script no início do body
      document.body.insertBefore(script, document.body.firstChild);

      // Limpeza ao desmontar o componente
      return () => {
        document.body.removeChild(script);
      };
    }
  }, []);  // O array vazio significa que este useEffect só é executado uma vez após o primeiro render
  

  return (
    <Layout backgroundColor="bg-white">
      <BreadcrumbComponent
        children={[
          { pageName: t("homePage"), path: "/blog" },
          {
            pageName: t(pageContext.category),
            path: `/${getCategoryName(pageContext.category)}`,
          },
          { pageName: pageContext.title },
        ]}
      />
      <main className="flex flex-col max-w-3xl mx-auto px-5 main-article">
        <div className="flex flex-wrap gap-y-3 md:flex-row mb-4">
          <Label
            fillColor={FillLabelColor.white}
            borderColor={BorderColor.black}
          >
            {t(getCategoryKey(pageContext.category))}
          </Label>
          <LevelLabel level={pageContext.level} />

          {pageContext.tags.map(tag => (
            <Label
              fillColor={FillLabelColor.white}
              borderColor={BorderColor.black}
            >
              {tag}
            </Label>
          ))}
        </div>
        <h1 className="!text-4xl md:!text-5xl">{pageContext.title}</h1>
        <h6>{pageContext.description}</h6>
        {pageContext.image != null && pageContext.category != "helpCenter" && (
          <GatsbyImage
            className="w-full mb-8"
            image={getImage(pageContext.image)!}
            alt={pageContext.title}
          />
        )}

        {parse(html, replaceImage)}
      </main>
      <div className="flex flex-col md:flex-row justify-center bg-purple-primary py-10">
        <h4 className="text-2xl text-white text-center font-poppins font-normal xs:mb-3 md:!mb-0 sm:mr-12 max-sm:px-5">
          {t("moreContentOnTheMedias")}
        </h4>
        <SocialNetworkRow className="fill-white hover:fill-purple-300" />
      </div>
      <div className="bg-soft-purple py-24">
        <div className="flex flex-col items-center max-w-8xl mx-auto">
          <h1 className="text-2xl md:text-5xl text-center font-poppins font-medium">
            {t("articlesThatWillInterest")}
          </h1>

          <div className="flex flex-col gap-x-4 justify-center mt-14 mb-20 xs:px-5 md:grid-cols-12 md:flex-row max-xl:grid sm:max-xl:px-10 max-xl:gap-x-8 max-xl:gap-y-8">
            {articlesList.map(article => (
              <ArticleCard
                to={`../../article/${article.slug}`}
                className="md:basis-3/6 lg:basis-3/12 w-full md:col-span-6"
                key={article.slug}
                title={article.title}
                level={article.level}
                description={article.description}
                readingTime={article.timeToRead}
                image={article.image}
              />
            ))}
          </div>

          <RoundedButton
            onClick={() =>
              (location.href = `/${getCategoryName(pageContext.category)}`)
            }
          >
            {t("seeMore")}
          </RoundedButton>

          <hr className="mt-8 w-full border-lines" />
        </div>
      </div>
    </Layout>
  )
}

export const result = graphql`
  query SuggestedArticles($id: String, $locale: String) {
    suggestedArticles: allStrapiArticle(
      limit: 4
      filter: { id: { ne: $id }, locale: { eq: $locale }, category: { ne: "market" } }
    ) {
      edges {
        node {
          ...ArticleCardFields
        }
      }
    }

    articleImages: strapiArticle(id: { eq: $id }) {
      body {
        medias {
          src
          localFile {
            childrenImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: WEBP
                webpOptions: { quality: 85 }
                quality: 85
              )
            }
          }
        }
      }
    }
  }
`

export const Head = ({ pageContext }) => {
  let publishedDate = pageContext.publishedAt.split("T")[0]
  let updatedDate =
    pageContext.updatedAt != null
      ? pageContext.updatedAt.split("T")[0]
      : publishedDate

  return (
    <SEO
      title={pageContext.title}
      description={pageContext.description}
      pathname={pageContext.locale + '/article/' + pageContext.slug}
      readingTime={pageContext.timeToRead}
      thumbnail={pageContext.image != null && getSrc(pageContext.image)}
      publishedAt={publishedDate}
      pageType="Article"
    />
  )
}

export default ArticlePage
